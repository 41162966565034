import {
  type DtoPageRow,
  type DtoPageSection,
  EnumsPageName,
} from '@lp-lib/api-service-client/public';

import { slugify } from '../../../src/utils/router';

export function PageDisplayName(pageName: EnumsPageName): string {
  switch (pageName) {
    case EnumsPageName.PageNameExplore:
      return 'Subscription (Logged Out)';
    case EnumsPageName.PageNamePublicHome:
      return 'Subscription (Logged In)';
    case EnumsPageName.PageNameLiveOtp:
      return 'Live Events (Logged Out)';
    case EnumsPageName.PageNameLiveLoggedIn:
      return 'Live Events (Logged In)';
    case EnumsPageName.PageNameZoom:
      return 'Zoom App';
    case EnumsPageName.PageNameStaging:
      return 'Beta Staging';
    case EnumsPageName.PageNameTrial:
      return 'Trial Users';
    default:
      return pageName;
  }
}

export function pageSectionAnchor(section: DtoPageSection): string {
  return (
    section.anchor || `${slugify(section.title)}-${section.id.substring(0, 8)}`
  );
}

export function pageRowAnchor(row: DtoPageRow): string {
  if (row.anchor) return row.anchor;
  if (row.tag) return `${slugify(row.tag.name)}-${row.id.substring(0, 8)}`;
  if (row.tagCollection)
    return `${slugify(row.tagCollection.name)}-${row.id.substring(0, 8)}`;
  return row.id;
}

export function selectSectionByAnchor(
  sections: DtoPageSection[] | null | undefined,
  anchor: string | null | undefined
): DtoPageSection | null {
  const normalizedAnchor = anchor?.replace('#', '');
  if (!sections || !normalizedAnchor) return null;
  return (
    sections?.find((section) => {
      if (pageSectionAnchor(section) === normalizedAnchor) return true;
      if (section.rows) {
        return section.rows.some((row) => pageRowAnchor(row) === anchor);
      }
      return false;
    }) || null
  );
}
